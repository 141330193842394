const MENUS = [
  {
    id: 'home',
    name: '首页',
  },
  {
    id: 'products',
    name: '产品介绍',
  },
  {
    id: 'scenes',
    name: '场景方案',
    children: [
      {
        id: 'channels',
        name: '全渠道获客管理',
      },
      {
        id: 'privacy',
        name: '私域流量运营',
      },
      {
        id: 'autoclue',
        name: '自动化线索培育',
      },
      {
        id: 'collaboration',
        name: '市场销售协同',
      },
    ],
  },
  {
    id: 'solutions',
    name: '行业方案',
    children: [
      {
        id: 'manufacturing',
        name: '工业制造行业',
      },
      {
        id: 'software',
        name: '高新软件行业',
      },
      {
        id: 'enterprise-service',
        name: '企业服务行业',
      },
      {
        id: 'chemical-raw-materials',
        name: '化工原材料行业',
      },
    ],
  },
  {
    id: 'allinhuiju',
    name: '荟聚一堂',
    children: [
      {
        id: 'blogs',
        name: '荟聚一堂',
      },
      {
        id: 'b2blession',
        name: 'B2B数字营销实践课程',
        href: 'https://a.huiju.cool/service/extcontent/page/f5cdd968712448afa6f2d1953590b3fc/home/1061',
      }
    ]
  },
  {
    id: 'industryinsights',
    name: '行业洞察',
  },
  {
    id: 'aboutus',
    name: '关于我们',
  },
];

export default MENUS;
export { MENUS };

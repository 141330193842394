import { MENUS } from '../constants';

export default function track({ name, content = '', current = 'home' }) {
  const link = MENUS.find((e) => e.id === current);
  const category = link ? link.name : '首页';
  try {
    if (window.clab_tracker) {
      window.clab_tracker.track('c_click_official_website', {
        c_url: window.location.href,
        c_button_title: name,
        c_group: '荟聚官网',
        c_category: category || '首页',
        c_content: content,
      });
    }
  } catch (err) {
    console.log(err);
  }
}

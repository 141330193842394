import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';

function Copyright() {
  const [beian, setBeian] = useState(`沪ICP备20015744号-3`);
  useEffect(() => {
    if (window.location.host === 'www.huiju.com.cn') {
      setBeian(`沪ICP备20015744号-6`);
    }
  }, []);
  return (
    <div id="copyright" className="copyright">
      <Container>
        <div className="content">
          <div className="item">上海欣京阳信息科技有限公司 </div>
          <div className="item">
            <a href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer noopener nofollow">
              {beian}
            </a>
          </div>
          <div className="item">© Copyright All Rights Reserved</div>
          <div className="item-right">
            <a href="/privacypolicy/" target="_blank" rel="noopener" className="pc-privacy">
              隐私政策
            </a>
            <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
            <a href="/childrenprivacy/" target="_blank" rel="noopener" className="pc-privacy">
              儿童隐私保护声明
            </a>
            <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
            <a href="/cookieprivacy/" target="_blank" rel="noopener" className="pc-privacy">
              Cookie政策
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Copyright;

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ApplyDialogContext from '../context/applyDialog';
import track from '../utils/track';
import CurrentContext from '../context/current';
import { useSearch } from '../utils/common';

export default function ApplyButton({
  children,
  size = 'medium',
  fluid = false,
  phone = false,
  className,
  menu = false,
  href,
  theme = 'default', // default black
  onClick,
  ...restProps
}) {
  const { setVisible } = useContext(ApplyDialogContext);
  const { current } = useContext(CurrentContext);
  const search = useSearch();

  let cName = 'apply-btn';
  if (size === 'large') {
    cName += ' apply-btn--large';
  }
  if (fluid) {
    cName += ' apply-btn--fluid';
  }
  if (phone) {
    cName += ' apply-btn--phone';
  }
  if (className) {
    cName += ` ${className}`;
  }
  if (theme) {
    cName += ` ${theme}`;
  }

  return (
    <span
      className={cName}
      onClick={() => {
        if (href) {
          window.open(`${href}${search}`);
        } else if (onClick) {
          onClick();
        } else {
          track({
            name: '申请试用',
            content: menu ? '顶部菜单' : '底部申请模块',
            current,
          });
          setVisible(true);
        }
      }}
      aria-hidden="true"
      {...restProps}
    >
      {children}
    </span>
  );
}

ApplyButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  fluid: PropTypes.bool,
  phone: PropTypes.bool,
  menu: PropTypes.bool,
  href: PropTypes.string || undefined,
};

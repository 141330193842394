import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ApplyDialogContext from '../context/applyDialog';
import track from '../utils/track';
import CurrentContext from '../context/current';

const NavMenu = [
  {
    title: '在线咨询',
    // icon: Counsel,
    iconId: 'Counsel',
  },
  {
    title: '关注微信',
    // icon: Weixin,
    iconId: 'Weixin',
  },
  {
    title: '申请试用',
    // icon: Apply,
    iconId: 'Apply',
  },
];

const WeiXinItem = ({ img, isBinding, desc, bindingId }) => {
  return (
    <div className="weixin-qrCode-wrapper">
      <div className="hover-arrow" />
      <div className="hover-content qrCode">
        {isBinding ? (
          <div
            className={bindingId ? '' : '_cl_qrcode_float'}
            id={bindingId}
            style={{ width: 105, height: 105 }}
          />
        ) : (
          <img src={img} alt="qrCode" />
        )}
        {desc && <p className="description">{desc}</p>}
      </div>
    </div>
  );
};

WeiXinItem.propTypes = {
  img: PropTypes.string,
  isBinding: PropTypes.bool,
  desc: PropTypes.string,
  bindingId: PropTypes.string,
};

// const PhoneCall = () => {
//   return (
//     <div className="tel-hover-wrapper">
//       <div className="hover-arrow" />
//       <div className="hover-content link">
//         <a href="tel:4008509918">400-850-9918</a>
//       </div>
//     </div>
//   );
// };

function FixedButtons() {
  const { setVisible } = useContext(ApplyDialogContext);
  const { current } = useContext(CurrentContext);
  function onBacktoTopClick() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  function onApplyForClick() {
    track({
      name: '申请试用',
      content: '固定右侧栏申请试用按钮',
      current,
    });
    setVisible(true);
  }
  const counselDesc = `添加企业微信\n1V1在线咨询`;
  const weixinDesc = `关注公众号\n获取一手营销洞察`;
  return (
    <div className="nav-btn-wrapper">
      {NavMenu.map((nav) => {
        return (
          <div className="nav-btn" key={nav.iconId}>
            <div
              className="nav-btn-content"
              onClick={nav.iconId === 'Apply' ? onApplyForClick : null}
            >
              <div className={`nav-btn-icon ${nav.iconId}`} />
              {nav.title !== '回到顶部' && <div className="icon-title">{nav.title}</div>}
              {nav.title === '在线咨询' && (
                <WeiXinItem
                  img=""
                  desc={counselDesc}
                  isBinding
                  bindingId="_cl_wework_qrcode_rightSide"
                />
              )}
              {nav.title === '关注微信' && <WeiXinItem img="" desc={weixinDesc} isBinding />}
              {/*{nav.title === '电话咨询' && <PhoneCall />}*/}
            </div>
          </div>
        );
      })}
      <div className="nav-btn" key="backtotop" onClick={onBacktoTopClick}>
        <div className="nav-btn-content no-text">
          <div className="nav-btn-icon Backtotop" />
        </div>
      </div>
    </div>
  );
}

export default FixedButtons;

const Data = {
  title: {
    home:
      '荟聚-tob营销工具软件系统_b2b营销工具软件系统产品_b2b营销平台_销售线索培育管理工具软件_数字化营销工具软件平台系统',
    products: '获客工具_获客软件_内容获客_网络获客',
    channels: '全渠道营销_全渠道获客',
    autoclue: '自动化线索培育_销售线索培育_销售线索管理_营销线索管理_营销线索培育',
    collaboration: '销售协同工具_市场销售协同_销售协同_营销协同',
    aboutus: '荟聚_营销获客_获客管理_获客工具软件',
    blogs: '工业品营销-高科技行业/内容营销-企业服务行业/化工原材料营销-荟聚一堂',
    'enterprise-service': '企业服务行业营销_会议营销',
    privacy: '私域流量运营_私域流量_内容营销管理',
    software: '全渠道营销_自动化营销_集客营销',
    manufacturing: '工业品营销_高科技行业营销',
    'chemical-raw-materials': '化工原材料营销_企业服务行业营销',
    privacypolicy: '隐私政策',
    childrenprivacy: '儿童隐私保护声明',
    cookieprivacy: 'Convertlab Cookie政策',
    industryinsights: 'b2b营销_tob营销_b2b营销工具_b2b营销产品_b2b营销平台',
  }, // e.g: 'Name | Developer'
  lang: 'zh-cn', // e.g: en, es, fr, jp
  keywords: {
    home:
      'tob营销工具,tob营销软件,tob营销系统,b2b营销产品,b2b营销软件,b2b营销系统,b2b营销工具,b2b营销平台,销售线索管理,数字化营销工具,数字化营销系统,销售线索培育,营销线索培育',
    products: '获客工具,获客软件,内容获客,网络获客,获客',
    channels: '全渠道营销,全渠道获客',
    autoclue: '自动化线索培育,销售线索培育,销售线索管理,营销线索管理,营销线索培育',
    collaboration: '销售协同工具,市场销售协同,销售协同,营销协同',
    aboutus: '荟聚,营销获客,获客管理,获客工具,获客软件',
    blogs: '营销产品,会议营销,工业品营销,高科技行业营销,内容营销,化工原材料营销,企业服务行业营销',
    'enterprise-service': '企业服务行业营销,会议营销',
    privacy: '私域流量运营,私域流量,内容营销管理',
    software: '全渠道营销,自动化营销,集客营销',
    manufacturing: '工业品营销,高科技行业营销',
    'chemical-raw-materials': '化工原材料营销,企业服务行业营销',
    privacypolicy: '隐私政策',
    childrenprivacy: '儿童隐私保护声明',
    cookieprivacy: 'Convertlab Cookie政策',
    industryinsights: 'b2b营销,tob营销,b2b营销工具,b2b营销产品,b2b营销平台',
  },
  description: {
    home:
      'Convertlab荟聚是服务B2B企业以线索管理为核心的营销自动化SaaS产品，包含全渠道获客管理、私域流量运营、自动化线索培育、市场销售协同四个核心部分。通过360度线索画像，不同获客渠道的链路及成本监测，营销自动化和线索分配自动化，帮助B2B企业实现精准获客增长，提高留资转化，优化营销投放的ROI和市场与销售的有机协同。', // e.g: Welcome to my website
    industryinsights: '荟聚B2B干货知识-营销人的学堂，市场人的资料库',
  },
  zcChannels: (key) => {
    let cid = 3;
    if (key === 'home') {
      cid = 3;
    } else if (key === 'products') {
      cid = 5;
    } else if (key === 'solutions') {
      cid = 6;
    } else if (key === 'cases') {
      cid = 7;
    } else if (key === 'aboutus') {
      cid = 8;
    }
    return cid;
  },
};

export default Data;

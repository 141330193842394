/* eslint-disable consistent-return */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import Menu from './Menu';
import Footer2 from './Footer2';
import Copyright from './Copyright';
import FixedButtons from './FixedButtons';
import ApplyDialog from './ApplyDialog';
import Data from '../data';
import ApplyDialogContext from '../context/applyDialog';
import CurrentContext from '../context/current';
import { useSearch } from '../utils/common';
import CookieRemind from './CookieRemindCpm';

function Layout({ current, children, subCurrent, type }) {
  const { title, lang, description, keywords } = Data;
  const [visible, setVisible] = useState(false);
  const search = useSearch();

  return (
    <CurrentContext.Provider value={{ current, subCurrent }}>
      <ApplyDialogContext.Provider value={{ visible, setVisible }}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{title[subCurrent || current] || title.home}</title>
          <html lang={lang} />
          <meta name="keywords" content={keywords[subCurrent || current] || keywords.home} />
          <meta
            name="description"
            content={description[subCurrent || current] || description.home}
          />
          <script>
            {`
              function docReady(fn) {
                // see if DOM is already available
                if (document.readyState === 'complete' || document.readyState === 'interactive') {
                  // call on next available tick
                  setTimeout(fn, 1);
                } else {
                  document.addEventListener('DOMContentLoaded', fn);
                }
              }
            `}
          </script>
          <script>
            {`
              docReady(function(){
                const oDate = new Date();
                oDate.setDate(oDate.getDate() - 1);
                document.cookie = "huiju_apply_shown=1;expires=" + oDate.toGMTString() + ";domain=.huiju.cool";
              });
            `}
          </script>
        </Helmet>
        {type !== 'privacy' && <Menu type={type} search={search} />}
        <div className={`layout-content ${type || ''}`}>{children}</div>
        {type !== 'privacy' && (
          <>
            <Footer2 type={type} current={current} />
            <Copyright />
            <FixedButtons />
            <ApplyDialog search={search} />
            <CookieRemind />
          </>
        )}
      </ApplyDialogContext.Provider>
    </CurrentContext.Provider>
  );
}

Layout.propTypes = {
  children: PropTypes.node,
  current: PropTypes.string,
  subCurrent: PropTypes.string,
  type: PropTypes.string,
};

export default Layout;

import React, { useContext, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Col, Toast, Image } from 'react-bootstrap';
import { Formik, Field } from 'formik';
import md5 from 'md5';
import Autocomplete from 'react-autocomplete';
import Select from 'react-select';
import ApplyDialogContext from '../context/applyDialog';
import CurrentContext from '../context/current';
import Warning from '../images/warning.svg';
import Success from '../images/success.svg';
import ApplyLeft1 from '../images/apply-left-1.svg';
// import ModalQrCode1 from '../images/modal-qrcode-1.jpg';
// import ModalQrCode2 from '../images/modal-qrcode-2.jpg';
import getCookie from '../utils/getCookie';
import track from '../utils/track';
import { loadQrcodeQw } from '../utils/common';

// 营销获客渠道选项
const options = [
  { value: '1', label: '企业微信', uuid: '11ba1884-c288-4037-b080-ffbad744e0ac' },
  { value: '2', label: '微信公众号', uuid: '718a2554-6d1a-427f-9bbd-bb74c1bcd109' },
  { value: '3', label: '官网', uuid: '2f782eaa-7284-4d35-a0d5-ae684d283e70' },
  { value: '4', label: '微信小程序', uuid: 'de89f4b7-d553-499b-b1e4-d6a191400090' },
  { value: '5', label: '视频号', uuid: '9fbbede6-13a5-4937-adb0-4a2c244b4bf1' },
  { value: '6', label: '抖音', uuid: '89c22bba-ce77-4c77-87a2-a033f0a148c1' },
  { value: '7', label: '线下会议', uuid: '5f13280e-b2d8-4a80-bf88-b2777fb1efa3' },
  { value: '8', label: '线上直播', uuid: '8621a493-fa53-4a7f-8247-5183402c1ae0' },
  { value: '9', label: '广告投放', uuid: 'eeba6e93-b063-4d92-bcc5-303d437cb28f' },
  { value: '10', label: '渠道合作', uuid: '4ada0ac9-391c-4df6-b0d1-05738ba4c334' },
  { value: '11', label: '邮件营销', uuid: '992dc010-4f98-4fe0-acb7-7adfa4b797cc' },
];

const { Group, Control } = Form;
const FORM_UUID_1 = '36da37a729cd44ef8cb3f12d6f9d7361';
const FORM_UUID_2 = '4b16f149ccd74e49be44a6d8c0b319ef';

const qrcodeTitle1 = `拨开B2B数字营销迷雾\n实现超级增长`;

const ApplyLeftContent = () => {
  return (
    <div className="apply-left-content">
      <h4>通过B2B行业解决方案Demo，您可以体验到：</h4>
      <p>① 用户标签管理和画像打造</p>
      <p>② 个性化精准营销提升客户体验</p>
      <p>③ 客户全生命周期管理和销售机会挖掘</p>
      <p>④ B2B行业客户培育运营及营销转化流程</p>
      <p>⑤ 运营策略模版化，以流程画布实现自动化运营</p>
      <Image src={ApplyLeft1} alt="apply-left-1" />
    </div>
  );
};

function getMd5Value() {
  const timeStamp = new Date().getTime();
  return timeStamp + md5(timeStamp + FORM_UUID_1.substr(0, 16));
}

function validateValue(value = '', name) {
  let error;
  if (name !== '营销获客渠道') {
    if (value.trim() === '') {
      error = `请输入${name}`;
    }
  } else if (!value || !value.length) {
    error = `请选择${name}`;
  }
  return error;
}

function validateName(value = '') {
  let error;
  if (value.trim() === '') {
    error = '请输入姓名';
  } else if (value.trim().length < 2) {
    error = '姓名不能少于两个字';
  } else if (value.trim().length > 50) {
    error = '姓名太长了';
  }
  return error;
}

function validateMobile(value = '') {
  let error;
  if (value.trim() === '') {
    error = '请输入手机号';
  } else if (!/^1[0-9]{10}$/.test(value.trim())) {
    error = '请输入正确的手机号格式';
  }
  return error;
}

function validateEmail(value = '') {
  let error;
  if (value.trim() === '') {
    error = '请输入企业邮箱';
  } else if (
    !/^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,4}$/.test(
      value.trim()
    )
  ) {
    error = '请输入正确的企业邮箱格式';
  }
  return error;
}

function validateCode(value = '') {
  let error;
  if (value.trim() === '') {
    error = '请输入验证码';
  } else if (!/[0-9]+$/.test(value.trim())) {
    error = '请输入正确的验证码格式';
  }
  return error;
}

function VerifyButton({ mobile, setToast, setCodeSended, current }) {
  const [countDown, setCountDown] = useState(0);

  useEffect(() => {
    if (countDown > 0) {
      setTimeout(() => {
        const curCountDown = countDown - 1;
        setCountDown(curCountDown > 0 ? curCountDown : 0);
      }, 1000);
    }
  }, [countDown]);

  const getCode = async () => {
    // const token = await fetch(`https://host.huiju.cool/sms/token?mobile=${mobile}`).then((res) =>
    //   res.text()
    // );
    const token = getMd5Value();
    const send = await fetch(
      `https://host.huiju.cool/sms/get?mobile=${mobile}&name=荟聚&token=${token}&type=form&uuid=${FORM_UUID_1}`
    ).then((res) => res.json());

    if (send.error) {
      throw new Error(send.error.message);
    }

    setCodeSended(true);

    return true;
  };

  const sendCode = async () => {
    track({
      name: '发送验证码',
      current,
    });
    if (countDown === 0) {
      const validateError = validateMobile(mobile);
      if (validateError) {
        setToast({ show: true, message: validateError });
        return false;
      }
      try {
        await getCode(mobile);
        setCountDown(60);
      } catch (err) {
        setToast({ show: true, message: err.message || '获取验证码出错，请稍后再试' });
      }
    }
    return true;
  };

  return (
    <div
      className={`verify-code-button form-button ${countDown > 0 ? 'count-down' : ''}`}
      onClick={sendCode}
      role="button"
      aria-hidden="true"
    >
      {countDown === 0 ? '发送验证码' : `已发送(${countDown}s)`}
    </div>
  );
}

VerifyButton.propTypes = {
  mobile: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setToast: PropTypes.func,
  setCodeSended: PropTypes.func,
  current: PropTypes.string,
};

const queryCache = {};

function ApplyModal(props) {
  const [submitting, setSubmitting] = useState(false);
  const [toast, setToast] = useState({ show: false });
  const [formData, setFormData] = useState(null);
  const [formData2, setFormData2] = useState(null);
  const [utma, setUtma] = useState('');
  const [utmb, setUtmb] = useState('');
  const [codeSended, setCodeSended] = useState(false);
  const { visible, setVisible } = useContext(ApplyDialogContext);
  const [items, setItems] = useState([]);
  const { current } = useContext(CurrentContext);
  const autoTimeout = useRef(null);
  const [visible2, setVisible2] = useState(false);
  const [qrCodeModalVisible, setQrCodeModalVisible] = useState(false);
  const [qrCodeModalVisible2, setQrCodeModalVisible2] = useState(false);
  const { search } = props;

  const loadFormToken = async (flag) => {
    try {
      const data = await fetch(
        `https://host.huiju.cool/formdata/get/${flag === 1 ? FORM_UUID_1 : FORM_UUID_2}`
      ).then((res) => res.json());
      if (flag === 1) {
        setFormData(data);
      } else {
        setFormData2(data);
      }
    } catch (err) {
      // console.log(err);
    }
  };

  useEffect(() => {
    if (visible) {
      loadFormToken(1);
      loadFormToken(2);
      const utma1 = getCookie('c__utma');
      const utmb1 = getCookie('c__utmb');
      setUtma(utma1);
      setUtmb(utmb1);
    }
  }, [visible]);

  const onSubmit = async (data, formikBag) => {
    if (!codeSended) {
      setToast({ show: true, message: '请先获取验证码' });
      return false;
    }

    if (!formData) {
      setToast({ show: true, message: '服务出错，请刷新页面再重试' });
      return false;
    }

    setSubmitting(true);

    try {
      const fData = new FormData();
      Object.keys(data).forEach((key) => fData.append(key, data[key]));
      fData.append('mobileVerified', true);
      fData.append('cltoken', formData.token);
      fData.append('utma', utma);
      fData.append('utmb', utmb);

      const response = await fetch(`https://host.huiju.cool/form/${FORM_UUID_1}${search}`, {
        method: 'POST',
        body: fData,
      });

      if (!response.ok) {
        const res = await response.text();
        if (res === 'InvalidMobileCode') {
          throw new Error('您输入的验证码有误，请重新获取');
        }
        if (res === 'LimitSubmit') {
          throw new Error('请勿重复提交');
        }
        if (res === 'SubmitFull') {
          throw new Error('提交已达上限');
        }
        throw new Error('服务出错，请稍后再试');
      }

      formikBag.resetForm();
      setToast({ show: true, message: '您已成功提交申请', type: 'success' });
      setVisible2(true);
      setVisible(false);
      document.cookie = `huiju_apply_shown=1;domain=.huiju.cool`;
      // document.cookie = `huiju_apply_shown=1;domain=.dmhub.cn`;
      // setTimeout(() => {
      //   setVisible(false);
      // }, 2000);
    } catch (err) {
      setToast({ show: true, message: err.message });
    } finally {
      setSubmitting(false);
    }

    return true;
  };

  const queryCompany = (keyword) => {
    const timestamp = new Date().getTime();
    const token = md5(`${FORM_UUID_1}${keyword}${timestamp}`);
    const data = {
      keyword,
      timestamp,
      token,
    };

    fetch(`https://host.huiju.cool/qixin/advSearch/${FORM_UUID_1}`, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res && res.rows && res.rows.length) {
          setItems(res.rows);
        }
      });
  };

  const onSubmit2 = async (data, formikBag) => {
    if (!formData2) {
      setToast({ show: true, message: '服务出错，请刷新页面再重试' });
      return false;
    }

    setSubmitting(true);

    try {
      const fData = new FormData();
      Object.keys(data).forEach((key) => {
        if (key === 'attr21') {
          return fData.append(key, data[key].map((item) => item.label).join(','));
        }
        return fData.append(key, data[key]);
      });
      fData.append('mobileVerified', true);
      fData.append('cltoken', formData2.token);
      fData.append('utma', utma);
      fData.append('utmb', utmb);


      const response = await fetch(`https://host.huiju.cool/form/${FORM_UUID_2}${search}`, {
        method: 'POST',
        body: fData,
      });

      if (!response.ok) {
        const res = await response.text();
        if (res === 'LimitSubmit') {
          throw new Error('请勿重复提交');
        }
        if (res === 'SubmitFull') {
          throw new Error('提交已达上限');
        }
        throw new Error('服务出错，请稍后再试');
      }

      formikBag.resetForm();
      // setToast({ show: true, message: '您已成功提交申请', type: 'success' });
      setQrCodeModalVisible2(true);
      setTimeout(() => {
        loadQrcodeQw('type2');
      }, 200);
      setVisible2(false);
      // setTimeout(() => {
      //   setVisible2(false);
      // }, 2000);
    } catch (err) {
      setToast({ show: true, message: err.message });
    } finally {
      setSubmitting(false);
    }

    return true;
  };

  const onChangeAutoComplete = (value, setFieldValue) => {
    setFieldValue('company', value);
    const v = value
      .trim()
      .replace(
        /[ |~|`|!|@|#|$|%|^|&|*|(|)|\-|_|+|=|||\\|[|\]|{|}|;|:|"|'|,|<|.|>|/|?|。|，|、|？|；|‘|“|：|！|（|）|——|·|「|」|『|』]/g,
        ''
      );

    if (autoTimeout.current) {
      clearTimeout(autoTimeout.current);
    }
    if (v.length < 2) {
      setItems([]);
    } else {
      if (queryCache[v]) {
        setItems(queryCache[v]);
        return;
      }

      autoTimeout.current = setTimeout(() => queryCompany(v), 600);
    }
  };

  // "预约演示"按钮直接打开的申请弹框【关闭事件】：
  // 如果是"第一次"关闭，显示第一个二维码弹框
  // "第一次"的判断逻辑：页面打开第一次后关闭 不限制时间 重新打开同一页面后 再次提示
  const handleFirstApplyHide = () => {
    setVisible(false);
    if (!document.cookie.includes('huiju_apply_shown=1')) {
      setQrCodeModalVisible(true);
      setTimeout(() => {
        loadQrcodeQw('type1');
      }, 200);
    }
    document.cookie = `huiju_apply_shown=1;domain=.huiju.cool`;
    // document.cookie = `huiju_apply_shown=1;domain=.dmhub.cn`;
  };

  return (
    <>
      <Modal
        {...props}
        show={visible}
        onHide={handleFirstApplyHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="apply-dialog"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="apply-container">
            <ApplyLeftContent />
            <div className="apply-right-content">
              <div>
                <h4 className="apply-title">免费体验DEMO</h4>
                <p className="apply-description">
                  请完善信息，我们会尽快核实信息，为您开通免费体验DEMO账号。
                </p>
                <div className="apply-form-container">
                  <Formik
                    onSubmit={onSubmit}
                    initialValues={{
                      name: '',
                      mobile: '',
                      code: '',
                      company: '',
                    }}
                    validateOnBlur={false}
                    validateOnChange={false}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      values,
                      errors,
                      validateField,
                      setFieldValue,
                    }) => (
                      <Form
                        noValidate
                        onSubmit={handleSubmit}
                        data-cl-attached="false"
                        data-cl-id={FORM_UUID_1}
                        autoComplete="off"
                      >
                        <Group controlId="applyName">
                          <Field
                            type="text"
                            name="name"
                            placeholder="姓名"
                            onChange={(e) => {
                              handleChange(e);
                              setTimeout(() => validateField('name'), 200);
                            }}
                            value={values.name}
                            className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                            validate={validateName}
                            maxLength={50}
                          />
                          <Control.Feedback type="invalid">{errors.name}</Control.Feedback>
                        </Group>
                        <Group controlId="applyMobile">
                          <Field
                            type="tel"
                            name="mobile"
                            placeholder="手机号"
                            onChange={(e) => {
                              handleChange(e);
                              setTimeout(() => validateField('mobile'), 200);
                            }}
                            value={values.mobile}
                            className={`form-control ${errors.mobile ? 'is-invalid' : ''}`}
                            validate={validateMobile}
                            maxLength={11}
                          />
                          <Control.Feedback type="invalid">{errors.mobile}</Control.Feedback>
                        </Group>
                        <Form.Row>
                          <Group as={Col} controlId="applyCode" span="auto" lg={7} xs={6}>
                            <Field
                              type="tel"
                              name="code"
                              placeholder="验证码"
                              onChange={(e) => {
                                handleChange(e);
                                setTimeout(() => validateField('code'), 600);
                              }}
                              value={values.code}
                              className={`form-control ${errors.code ? 'is-invalid' : ''}`}
                              validate={validateCode}
                              maxLength={6}
                            />
                            <Control.Feedback type="invalid">{errors.code}</Control.Feedback>
                          </Group>
                          <Col lg={5} xs={6}>
                            <VerifyButton
                              mobile={values.mobile}
                              setToast={setToast}
                              setCodeSended={setCodeSended}
                              current={current}
                            />
                          </Col>
                        </Form.Row>
                        <Group controlId="applyCompany">
                          <Field
                            name="company"
                            validate={(v) => validateValue(v, '公司名称')}
                            value={values.company}
                          >
                            {({ field }) => {
                              return (
                                <>
                                  <Autocomplete
                                    {...field}
                                    onChange={(e) => {
                                      onChangeAutoComplete(e.target.value, setFieldValue);
                                      setTimeout(() => validateField('company'), 600);
                                    }}
                                    getItemValue={(item) => item.name}
                                    items={items}
                                    renderItem={(item, isHighlighted) => (
                                      <div
                                        style={{ background: isHighlighted ? '#ffdb41' : 'white' }}
                                        key={item.id}
                                      >
                                        {item.name}
                                      </div>
                                    )}
                                    onSelect={(val) => setFieldValue('company', val)}
                                    wrapperStyle={{ width: '100%' }}
                                    inputProps={{
                                      className: `form-control ${
                                        errors.company ? 'is-invalid' : ''
                                      }`,
                                      placeholder: '公司名称',
                                      name: 'company',
                                    }}
                                    renderMenu={(_items, _value, style) => {
                                      const ms = {
                                        borderRadius: '3px',
                                        boxShadow: 'initial',
                                        background: 'rgba(255, 255, 255, 0.9)',
                                        padding: '0',
                                        marginTop: '2px',
                                        fontSize: '90%',
                                        position: 'fixed',
                                        overflow: 'auto',
                                        maxHeight: '50%',
                                        border: '1px solid #ddd',
                                        cursor: 'pointer',
                                      };
                                      return _items && _items.length > 0 ? (
                                        <div style={{ ...style, ...ms }}>{_items}</div>
                                      ) : (
                                        <></>
                                      );
                                    }}
                                  />
                                </>
                              );
                            }}
                          </Field>
                          {errors.company && (
                            <div
                              style={{
                                textAlign: 'left',
                                width: '100%',
                                marginTop: '.25rem',
                                fontSize: '80%',
                                color: '#dc3545',
                              }}
                            >
                              {errors.company}
                            </div>
                          )}
                        </Group>
                        <button
                          className="btn apply-submit-button form-button"
                          type="submit"
                          disabled={submitting}
                          onClick={() => track({ name: '提交申请', current })}
                        >
                          {submitting ? '提交中...' : '提交申请'}
                        </button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={visible2}
        onHide={() => setVisible2(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="apply-dialog"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="apply-container">
            <ApplyLeftContent />
            <div className="apply-right-content">
              <div>
                <h4 className="apply-title">申请成功</h4>
                <p className="apply-description">
                  我们已经收到您的申请信息，谢谢您对Convertlab荟聚的信赖，我们的咨询顾问将会与您联系。
                  您还可以继续完善以下信息，以方便为您提供更优质的服务。
                </p>
                <div className="apply-form-container">
                  <Formik
                    onSubmit={onSubmit2}
                    initialValues={{
                      email: '',
                      attr21: undefined,
                    }}
                    validateOnBlur={false}
                    validateOnChange={false}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      values,
                      errors,
                      validateField,
                      setFieldValue,
                    }) => (
                      <Form
                        noValidate
                        onSubmit={handleSubmit}
                        data-cl-attached="false"
                        data-cl-id={FORM_UUID_2}
                        autoComplete="off"
                      >
                        <Group controlId="applyEmail">
                          <Field
                            type="email"
                            name="email"
                            placeholder="企业邮箱"
                            onChange={(e) => {
                              handleChange(e);
                              setTimeout(() => validateField('email'), 200);
                            }}
                            value={values.email}
                            className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                            validate={validateEmail}
                          />
                          <Control.Feedback type="invalid">{errors.email}</Control.Feedback>
                        </Group>
                        <Group controlId="applyChannels">
                          <Field
                            name="attr21"
                            validate={(v) => validateValue(v, '营销获客渠道')}
                            placeholder="贵司有哪些营销获客渠道？(多选)"
                            value={values.attr21}
                          >
                            {() => {
                              return (
                                <>
                                  <Select
                                    name="attr21"
                                    placeholder="贵司有哪些营销获客渠道？(多选)"
                                    className="apply-select"
                                    options={options}
                                    isMulti
                                    // menuIsOpen
                                    value={values.attr21}
                                    closeMenuOnSelect={false}
                                    onChange={(selectedList) => {
                                      setFieldValue('attr21', selectedList);
                                    }}
                                  />
                                  {!(values.attr21 || []).length && (
                                    <div
                                      style={{
                                        textAlign: 'left',
                                        width: '100%',
                                        marginTop: '.25rem',
                                        fontSize: '80%',
                                        color: '#dc3545',
                                      }}
                                    >
                                      {errors.attr21}
                                    </div>
                                  )}
                                </>
                              );
                            }}
                          </Field>
                        </Group>
                        <button
                          className="btn apply-submit-button form-button"
                          type="submit"
                          disabled={submitting}
                          onClick={() => track({ name: '提交申请', current })}
                        >
                          {submitting ? '提交中...' : '提交申请'}
                        </button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={qrCodeModalVisible}
        onHide={() => setQrCodeModalVisible(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="apply-dialog qr-modal-1"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="qrcode-container">
            <div className="qrcode-content">
              <h4 className="apply-title">{qrcodeTitle1}</h4>
              <div className="qrcode-image-border" id="_cl_wework_qrcode_1">
                {/*<img className="qrcode-image" src={ModalQrCode1} alt="qr-1" />*/}
              </div>
              <p className="qrcode-description">扫码添加微信</p>
              <div className="title-wrapper title-bg">
                <div className="background-bar" />
                <p>下载B2B数字营销解决方案</p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={qrCodeModalVisible2}
        onHide={() => setQrCodeModalVisible2(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="apply-dialog qr-modal-2"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="qrcode-container">
            <div className="qrcode-content">
              <h4 className="apply-title">提交成功</h4>
              <div className="qrcode-image-border" id="_cl_wework_qrcode_2">
                {/*<img className="qrcode-image" src={ModalQrCode2} alt="qr-2" />*/}
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <p className="qrcode-description">扫码添加微信，</p>
                <div className="title-wrapper title-bg">
                  <div className="background-bar" />
                  <p>获得咨询顾问1VS1在线服务</p>
                </div>
              </div>
              <p className="qrcode-description">还有更多B2B营销知识洞察</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Toast
        onClose={() => setToast({ show: false })}
        show={toast.show}
        delay={2000}
        className="apply-form-warning-toast"
        autohide
      >
        <Toast.Body>
          <Image src={toast.type === 'success' ? Success : Warning} style={{ paddingRight: 14 }} />
          {toast.message}
        </Toast.Body>
      </Toast>
    </>
  );
}

export default ApplyModal;

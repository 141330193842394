import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Navbar, Nav, Image, Dropdown } from 'react-bootstrap';
import LogoImage from '../images/logo.svg';
import LogoWhite from '../images/logo-white.svg';
import ApplyButton from './ApplyButton';
import CurrentContext from '../context/current';
import { MENUS } from '../constants';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/main.scss';

function CustomDropdownButton({ title, onClick, className, children, show }) {
  return (
    <div className={className}>
      <button
        aria-haspopup
        aria-expanded="false"
        type="button"
        className="dropdown-toggle btn btn-primary"
        onClick={onClick}
      >
        {title}
      </button>
      <div className={`custom-dropdown-menu ${show ? 'open' : ''}`} style={{ margin: 0 }}>
        {children}
      </div>
    </div>
  );
}

CustomDropdownButton.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node,
  show: PropTypes.bool,
};

function Menu({ type, search }) {
  const { current, subCurrent } = useContext(CurrentContext);
  const [scrollTop, setScrollTop] = useState(true);
  const [menuExpanded, setMenuExpanded] = useState(false);
  const [currentShowDropdown, setCurrentShowDropdown] = useState(null);

  const listenScroll = () => {
    const scrollTop1 = document.documentElement.scrollTop || document.body.scrollTop;
    if (scrollTop1 >= 10 && scrollTop) {
      setScrollTop(false);
    } else if (scrollTop1 < 10 && !scrollTop) {
      setScrollTop(true);
    }
  };

  useEffect(() => {
    listenScroll();
    document.addEventListener('scroll', () => {
      listenScroll();
    });
  }, [scrollTop]);

  const onToggle = (expanded) => {
    setMenuExpanded(expanded);
    document.body.style.overflow = expanded ? 'hidden' : 'scroll';
  };

  const onSelect = (dropdownId) => {
    setCurrentShowDropdown(currentShowDropdown === dropdownId ? null : dropdownId);
  };

  const targetItem = subCurrent || current;

  return (
    <Navbar
      expand="lg"
      className={`hj-navbar ${type} ${scrollTop ? 'scroll-top' : ''} ${
        menuExpanded ? 'expanded' : ''
      }`}
      onToggle={onToggle}
    >
      <Container>
        <Navbar.Brand href={`/${search}`}>
          <Image
            src={
              scrollTop && !menuExpanded && type !== 'blogs' && type !== 'products'
                ? LogoWhite
                : LogoImage
            }
            className="brand"
          />
        </Navbar.Brand>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {MENUS.map((e) => {
              const className =
                current && current.indexOf(e.id) >= 0 ? 'nav-item nav-item-focus' : 'nav-item';
              if (e.children) {
                return (
                  <CustomDropdownButton
                    key={e.id}
                    title={e.name}
                    show={currentShowDropdown === e.id}
                    onClick={() => onSelect(e.id)}
                    className={`${className} ${
                      currentShowDropdown === e.id ? 'open' : ''
                    } dropdown nav-link has-children`}
                  >
                    {e.children.map((child) => {
                      return (
                        <Dropdown.Item
                          key={child.id}
                          className={child.id}
                          href={child.href ? child.href : `/${child.id}/${search}`}
                          active={targetItem === child.id}
                        >
                          {child.name}
                        </Dropdown.Item>
                      );
                    })}
                  </CustomDropdownButton>
                );
              }
              return (
                <Nav.Link
                  key={e.id}
                  href={`/${e.id === 'home' ? '' : `${e.id}/`}${search}`}
                  className={className}
                >
                  {e.name}
                </Nav.Link>
              );
            })}
          </Nav>
        </Navbar.Collapse>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <div className="menu-apply-button-container">
          <ApplyButton phone menu>
            免费体验DEMO
          </ApplyButton>
        </div>
      </Container>
    </Navbar>
  );
}

Menu.propTypes = {
  type: PropTypes.string,
};

export default Menu;

import React from 'react';
import { Container } from 'react-bootstrap';

const FRIENDLIST = [
  { title: '人群画像', href: 'https://www.convertlab.com/', id: '001', rel: '' },
  { title: '上海网站建设', href: 'https://www.zhutengtech.com/', id: '002', rel: '' },
  { title: '宣传片拍摄', href: 'http://www.kustudio.cn/', id: '003', rel: '' },
  { title: '品牌设计', href: 'http://www.heyude.com.cn/', id: '004', rel: '' },
  { title: '包装设计', href: 'http://www.zhutengmarketing.com/', id: '007', rel: '' },
  { title: '开水机', href: 'http://www.idiy.cc/', id: '009', rel: '' },
  { title: '打印贴标机', href: 'https://www.ktthtech.com/', id: '010', rel: '' },
  { title: '钣金加工', href: 'https://www.ycj.com.cn/', id: '012', rel: '' },
  { title: '品牌设计', href: 'http://www.wubrand.com/', id: '014', rel: '' },
  { title: '西威变频器', href: 'http://www.shwydq.com/', id: '016', rel: '' },
  {
    title: '新行业加盟',
    href: 'http://new.91jm.com/',
    id: '023',
    rel: '',
  },
  {
    title: '食材配送软件',
    href: 'http://www.hnsbxl.com/',
    id: '026',
    rel: '',
  },
  {
    title: '校园一卡通虚拟化',
    href: 'http://www.usbserver.com/',
    id: '027',
    rel: '',
  },
  {
    title: '短视频运营',
    href: 'https://www.shipindong.com/',
    id: '033',
    rel: '',
  },
  {
    title: '呼叫中心系统',
    href: 'https://www.hollycrmcloud.com/',
    id: '034',
    rel: '',
  },
  {
    title: '深圳车牌识别系统',
    href: 'http://www.0755ybc.com/',
    id: '040',
    rel: '',
  },
  {
    title: '深圳封阳台',
    href: 'http://www.shujing-sz.com/',
    id: '043',
    rel: '',
  },
  {
    title: '私域电商系统',
    href: 'https://www.yunliebian.com/',
    id: '044',
    rel: '',
  },
  {
    title: '上海猎头',
    href: 'http://www.shjuejia.com/',
    id: '045',
    rel: '',
  },
  {
    title: '阿九源码站',
    href: 'https://www.vip5178.com/',
    id: '046',
    rel: '',
  },
  {
    title: '制氮机',
    href: 'http://www.huagongyuan-gas.com/',
    id: '048',
    rel: '',
  },
];

export default function FriendlyLinks() {
  return (
    <Container>
      <div id="friendly-links" className="friendly-links">
        <div className="content">
          {FRIENDLIST.map((item) => (
            <div className="item" key={item.id}>
              <a href={item.href} target="_blank" rel={item.rel}>
                {item.title}
              </a>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
}

/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import {
  useSearch,
  loadScript,
  loadQrcodeQw,
  loadQrcode,
  loadBadiuhmScript,
} from '../utils/common';

const CookieRemind = () => {
  const search = useSearch();

  // 先加载埋点脚本，再加载动态二维码
  const getCollect = (useCookie = true) => {
    const loadCodes = () => {
      loadQrcode('footer');
      loadQrcode('article');
      loadQrcode('float');
      loadQrcodeQw('type3');
      loadQrcodeQw('type4');
      loadQrcodeQw('type5');
    };
    if (useCookie) {
      loadScript('//cbe.huiju.cool/cbe/collect?tid=1238467299&at=0&h=web', () => {
        const clParamMap = {};
        try {
          // eslint-disable-next-line no-restricted-syntax
          for (const params of search.slice(1).split('&')) {
            const [key, value] = params.split('=');
            if (key.indexOf('cl_') === 0 && value) {
              clParamMap[key] = value;
            }
          }
        } catch (err) {
          console.log(err);
        }

        clab_tracker.ready(function () {
          this.push(Object.assign({}, clParamMap));
          this.track('open_page', {});
          setTimeout(() => {
            loadCodes();
          }, 600);
        });
        window.clabTracker = clab_tracker;
      });
    } else {
      loadCodes();
    }
  };

  const loadCpm = () => {
    loadScript('//cpm-api.convertlab.com/sdkApi/cookie/config?x-tenant-id=1', () => {
      setTimeout(() => {
        if (typeof clab_cpm !== 'undefined') {
          clab_cpm.init();
        }
      }, 200);
    });
  };

  useEffect(() => {
    loadBadiuhmScript();
    getCollect();
    loadCpm();
  }, []);
  return <div></div>;
};

export default CookieRemind;
